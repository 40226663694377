import React from "react";

interface PaginationProps {
    currentPage: number,
    maxPages: number,
    selectPage: (page: number) => void,
}

const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
    const isFirstPage = props.currentPage === 0
    const isLastPage = props.currentPage === props.maxPages

    const previousPage = isFirstPage ? 0 : props.currentPage - 1
    const nextPage = isLastPage ? props.currentPage : props.currentPage + 1
    return (
        <>
            <ul className="pagination justify-content-end">
                <li className={"page-item" + (isFirstPage ? ' disabled' : '')}>
                    <a className="page-link" onClick={_ => props.selectPage(previousPage)}>Назад</a>
                </li>

                <li className={"page-item" + (isLastPage ? ' disabled' : '')}>
                    <a className="page-link" href="#" onClick={_ => props.selectPage(nextPage)}>Вперед</a>
                </li>
            </ul>
        </>
    )
}

export default Pagination