import React from "react"
import Chat from "../types/Chat";
import ChatService from "../services/ChatService";
import Pagination from "./Pagination";

type ChatsState = {
    chats: Array<Chat>
    page: number
    isLastPage: boolean
}

interface ChatsProps {
}

const SINGLE_PAGE_SIZE = 25
const SUPPORTED_TYPES = ["SUPERGROUP", "GROUP"] // TODO make a filter

class Chats extends React.Component<ChatsProps, ChatsState> {

    state = {
        chats: [] as Array<Chat>,
        page: 0,
        isLastPage: false
    }

    constructor(props: ChatsProps) {
        super(props);
        this.fetchChats = this.fetchChats.bind(this)
        this.selectPage = this.selectPage.bind(this)
    }

    fetchChats(page: number, types: Array<string>) {
        ChatService.findAll(page, types).then((response: any) => {
            this.setState({
                // chats: response.data
                chats: response.data,
                page: page,
                isLastPage: response.data.length < SINGLE_PAGE_SIZE
            })
        })
    }

    selectPage(page: number) {
        this.fetchChats(page, SUPPORTED_TYPES)
    }

    componentDidMount() {
        this.fetchChats(0, SUPPORTED_TYPES)
    }

    render() {
        const maxPages = this.state.isLastPage ? this.state.page : 100500
        const chatTitle = (chat: Chat) => {
            if (chat.username == null) {
                return (<>{chat.title}</>)
            } else {
                return (<>{chat.title} <b className="text-primary">[@{chat.username}]</b></>)
            }
        }
        return (
            <div>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th style={{width: 100}}>Участники</th>
                        <th>Название</th>
                        <th className="text-center" style={{width: 200}}>Тип</th>
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">
                    {this.state.chats.map((item, _) => {
                        const type = item.type === "SUPERGROUP" ? "Супергруппа" : "Группа"
                        return (
                            <tr key={item.id.toString()}>
                                <td className="text-center">{item.members.toString()}</td>
                                <td>{chatTitle(item)}</td>
                                <td className="text-center">{type}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Pagination currentPage={this.state.page} maxPages={maxPages} selectPage={this.selectPage}/>
            </div>
        )
    }
}

export default Chats;