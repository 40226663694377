import axios, {AxiosResponse} from "axios";
import TokenService from "./TokenService";

const baseURL = "https://fortuneteller-api.cargeh.dev/"
// const baseURL = "http://localhost:8010/"

const axiosBase = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json"
    }
});

function get<T = any, R = AxiosResponse<T>, D = any>(url: string): Promise<R> {
    return axiosBase.get<T, R, D>(url, {
        headers: getAdditionalHeaders()
    }).catch(errorHandling)
}

function post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
    return axiosBase.post<T, R, D>(url, data, {
        headers: getAdditionalHeaders()
    }).catch(errorHandling)
}

const getAdditionalHeaders = () => {
    const token = TokenService.getToken()
    if (token == null) {
        return
    }
    return {
        "Authorization": "Bearer " + token
    }
}

const errorHandling = (e: any) => {
    const responseStatus = e.response.status
    if (responseStatus === 401 || responseStatus === 403) {
        TokenService.removeToken()
    } else {
        console.log(e)
    }
    throw e
}

const AxiosService = {
    get,
    post
}

export default AxiosService;