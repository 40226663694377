import React, {BaseSyntheticEvent, Component} from 'react';
import Prediction from "../../types/Prediction";
import PredictionService from "../../services/PredictionService";
import PredictionMedia from "../../types/PredictionMedia";
import {mediaInput} from "./MediaInput";

interface PredictionFormProps {
    submitCallback: () => void
}

type PredictionFormState = {
    predictionText?: string | null;
    media?: PredictionMedia | null
}

class PredictionForm extends Component<PredictionFormProps, PredictionFormState> {

    state = {
        predictionText: null,
        media: null
    }

    constructor(props: PredictionFormProps) {
        super(props);
        this.createPrediction = this.createPrediction.bind(this)
        this.onButtonClick = this.onButtonClick.bind(this)
    }

    createPrediction(text?: string | null, media?: PredictionMedia | null) {
        const prediction = {
            text: text,
            media: media,
            active: true
        } as Prediction

        PredictionService.save(prediction)
            .then(_ => this.props.submitCallback())
    }

    onButtonClick(e: BaseSyntheticEvent) {
        e.preventDefault()

        if (this.state.predictionText == null && this.state.media == null) {
            alert("Должен присутствовать либо текст, либо медиа.")
            return
        }

        this.createPrediction(this.state.predictionText, this.state.media)
        this.setState({
            predictionText: null,
            media: null
        });

        // value of a form cannot be set to null, so have to clear it like this
        (document.getElementById("prediction-text-input") as HTMLInputElement).value = "";
        (document.getElementById("prediction-file-input") as HTMLInputElement).value = "";

        this.props.submitCallback()
    }

    render() {
        return (
            <>
                <form>
                    <div className="row d-flex justify-content-center">
                        <div className="row">
                            <label htmlFor="text" className="form-label">Текст</label>
                            <input
                                id="prediction-text-input"
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                    this.setState({
                                        predictionText: e.target.value
                                    })
                                }}
                            />
                            {
                                mediaInput(media => {
                                    this.setState({
                                        media: media
                                    })
                                })
                            }
                            <div id="textHelp" className="form-text">
                                %username% - подставится имя пользователя<br/>
                                %random_member% - подставится случайное имя человека из того чата, откуда был вызов
                            </div>
                        </div>
                        <div className="prediction-new-button row">
                            <button type="submit" className="btn btn-success" onClick={this.onButtonClick}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

export default PredictionForm;