const TOKEN_KEY = "jwtToken"

const getToken = () => {
    return localStorage.getItem(TOKEN_KEY) as string
};

const saveToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token)
};

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY)
    window.location.reload()
}

const TokenService = {
    getToken,
    saveToken,
    removeToken
};

export default TokenService;