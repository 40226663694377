import {useState} from 'react';
import TokenService from "../services/TokenService";

export default function useToken() {
    const [token, setToken] = useState<string | null>(TokenService.getToken);

    const saveToken = (userToken?: string) => {
        if (userToken === null) {
            TokenService.removeToken()
            setToken(null)
        } else {
            const token = userToken!!
            TokenService.saveToken(token)
            setToken(token);
        }
    };

    return {
        token,
        setToken: saveToken
    }
}