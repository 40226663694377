import React from "react";

const Analytics: React.FunctionComponent = () => {
    return (
        <>
            <p>Analytics</p>
        </>
    )
}

export default Analytics