import AuthResponse from "../types/AuthResponse";
import AuthRequest from "../types/AuthRequest";
import AxiosService from "./AxiosService";

const auth = (name: string, password: string) => {
    const request = {
        username: name,
        password: password
    } as AuthRequest
    return AxiosService.post<AuthResponse>("/auth", request)
}

const AuthService = {
    auth
}

export default AuthService;