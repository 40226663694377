import React from "react";
import Prediction from "../../types/Prediction";
import PredictionService from "../../services/PredictionService";
import PredictionMedia from "../../types/PredictionMedia";
import Image from "react-image-enlarger";
import {mediaInput} from "./MediaInput";

interface PredictionRowProps {
    item: Prediction,
}

type PredictionRowState = {
    text?: string | null // can be modified by input fields
    originalText?: string | null, // original, immutable in between fetches
    media?: PredictionMedia | null, // can be modified by input fields
    originalMedia?: PredictionMedia | null, // original, immutable in between fetches
    active: boolean,
    isEditing: boolean,
    zoomed: boolean,
}

class PredictionRow extends React.Component<PredictionRowProps, PredictionRowState> {

    state = {
        text: this.props.item.text,
        originalText: this.props.item.text,
        media: this.props.item.media,
        originalMedia: this.props.item.media,
        active: this.props.item.active,
        isEditing: false,
        zoomed: false,
    }

    constructor(props: PredictionRowProps) {
        super(props);
        this.id = this.id.bind(this);
        this.fetch = this.fetch.bind(this);
        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    id() {
        return this.props.item.id!!
    }

    fetch() {
        PredictionService.findById(this.id()).then(r => {
            this.setState({
                text: r.data.text,
                originalText: r.data.text,
                media: r.data.media,
                originalMedia: r.data.media,
                active: r.data.active,
                isEditing: false,
            })
        })
    }

    activate() {
        PredictionService.activate(this.id()).then(r => this.fetch())
    }

    deactivate() {
        PredictionService.deactivate(this.id()).then(r => this.fetch())
    }

    saveChanges() {
        const prediction = {
            id: this.id(),
            text: this.state.text,
            media: this.state.media,
            active: this.state.active
        } as Prediction

        PredictionService.save(prediction).then(_ => {
            this.fetch()
        })
    }

    render() {
        const idString = this.props.item.id?.toString() || "null"

        const buttons = () => {
            if (this.state.isEditing) {
                return (
                    <>
                        <button className="btn btn-success" role="button" onClick={this.saveChanges}>
                            Сохранить
                        </button>
                        <button className="btn btn-secondary"
                                role="button"
                                onClick={_ => this.setState({
                                    isEditing: false,
                                    text: this.state.originalText
                                })}>
                            Отменить
                        </button>
                    </>
                )
            } else {
                return (
                    <>
                        <button className="btn btn-primary"
                                role="button"
                                onClick={_ => this.setState({isEditing: true})}>
                            Изменить
                        </button>
                        {this.state.active
                            ? <button className="btn btn-danger" role="button"
                                      onClick={this.deactivate}>Отключить</button>
                            :
                            <button className="btn btn-success" role="button" onClick={this.activate}>Включить</button>
                        }
                    </>
                )
            }
        }

        const renderContent = () => {
            if (this.state.isEditing) {
                const currentMediaId = this.state.media?.id
                return (
                    <>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.text}
                            onChange={(e) => {
                                this.setState({
                                    text: e.target.value
                                })
                            }}
                        />
                        {
                            mediaInput(media => {
                                this.setState({
                                    media: {
                                        ...media,
                                        id: currentMediaId ? currentMediaId : null // to make sure update works
                                    }
                                })
                            })
                        }
                    </>
                )
            } else {
                const media = this.state.media
                if (media == null) {
                    return this.state.text
                } else {
                    if (this.state.text == null) {
                        return image()
                    } else {
                        return (
                            <span className="prediction-with-media">
                                {image()}
                                <p className="prediction-text">{this.state.text}</p>
                            </span>
                        )
                    }
                }
            }
        }

        const image = () => {
            const media = this.state.media!
            if (media.type !== 'image/jpeg' && media.type !== 'image/png') {
                alert("Unknown media type: " + media.type)
            }
            return <Image
                zoomed={this.state.zoomed}
                className="prediction-img img-thumbnail"
                src={"data:" + media.type + ";base64," + media.data}
                onClick={() => {
                    this.setState({
                        zoomed: true
                    })
                }}
                onRequestClose={() => {
                    this.setState({
                        zoomed: false
                    })
                }}
            />
        }

        return (
            <tr className={this.state.active ? "" : "table-danger"}>
                <td>{idString}</td>
                <td>{renderContent()}</td>
                <td>
                    {buttons()}
                </td>
            </tr>
        )
    }
}

export default PredictionRow;