import UsageStats from "../types/UsageStats";
import AxiosService from "./AxiosService";

const getStats = () => {
    return AxiosService.get<UsageStats>("/analytics/stats")
};

const AnalyticsService = {
    getStats
};

export default AnalyticsService;