import React, {Component} from 'react';
import Prediction from "../../types/Prediction";
import PredictionService from "../../services/PredictionService";
import PredictionForm from "./PredictionForm";
import PredictionsTable from "./PredictionsTable";
import Form from 'react-bootstrap/Form';

type PredictionState = {
    predictions: Array<Prediction>,
    skipNonActive: boolean
}

interface PredictionProps {
}

class Predictions extends Component<PredictionProps, PredictionState> {
    state = {
        predictions: [] as Array<Prediction>,
        skipNonActive: false
    }

    constructor(props: PredictionProps) {
        super(props);
        this.fetchPredictions = this.fetchPredictions.bind(this)
    }

    fetchPredictions() {
        PredictionService.findAll().then((response: any) => {
            this.setState({
                predictions: response.data
            })
        })
    }

    componentDidMount() {
        this.fetchPredictions()
    }

    render() {
        return (
            <>
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <h1 className="h2">Сотворить предсказание</h1>
                </div>
                <PredictionForm submitCallback={this.fetchPredictions}/>

                <br/>
                <hr/>
                <br/>

                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <h1 className="h2">Предсказания</h1>
                </div>
                <div className="form-check d-flex flex-row-reverse">
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Скрывать неактивные"
                        onChange={e => this.setState({skipNonActive: e.target.checked})}
                    />
                </div>
                <PredictionsTable predictions={this.state.predictions} skipNonActive={this.state.skipNonActive}/>
            </>
        )
    }
}

export default Predictions
