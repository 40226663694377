import React from "react";
import {HeaderData} from "./HeaderData";
import {useLocation} from "react-router-dom";
import TokenService from "../services/TokenService";

const Header: React.FunctionComponent = () => {
    const location = useLocation();
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">Предсказатель</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                {HeaderData.map((item, index) => {
                                    const isActive = item.path === location.pathname
                                    return (
                                        <li key={item.path} className="nav-item">
                                            <a className={`nav-link ${isActive ? "active" : ""}`} href={item.path}>
                                                <span className="align-text-bottom"></span>
                                                {item.title}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="d-flex">
                            <button type="button" className="btn btn-secondary" onClick={TokenService.removeToken}>
                                Выйти
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header