import Prediction from "../types/Prediction";
import AxiosService from "./AxiosService";

const findAll = () => {
    return AxiosService.get<Prediction>("/predictions")
}

const findById = (id: bigint) => {
    return AxiosService.get<Prediction>("/predictions/" + id)
}

const save = (prediction: Prediction) => {
    return AxiosService.post<Prediction>("/predictions/save", prediction)
}

const activate = (id: bigint) => {
    return AxiosService.post("/predictions/activate", id)
}

const deactivate = (id: bigint) => {
    return AxiosService.post("/predictions/deactivate", id)
}

const PredictionService = {
    findAll,
    findById,
    save,
    activate,
    deactivate
};

export default PredictionService;