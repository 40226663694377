import React from "react";
import PredictionRow from "./PredictionRow";
import Prediction from "../../types/Prediction";

interface PredictionsTableProps {
    predictions: Array<Prediction>,
    skipNonActive: boolean
}

const PredictionsTable: React.FunctionComponent<PredictionsTableProps> = (props) => {
    const predictions = props.predictions.filter(item => {
        return props.skipNonActive ? item.active : true
    })
    return (
        <table className="table table-hover prediction-table align-middle">
            <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Предсказание</th>
                <th scope="col" style={{width: 260}}></th>
            </tr>
            </thead>
            <tbody>
            {predictions.map((item, _) => {
                return (
                    <PredictionRow key={item.id?.toString()} item={item}/>
                )
            })}
            </tbody>
        </table>
    )
}

export default PredictionsTable;