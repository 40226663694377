import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Header from './components/Header'
import Analytics from "./components/Analytics";
import Login from "./components/Login";
import Predictions from './components/prediction/Predictions'
import {Overview} from "./components/Overview";
import useToken from "./util/useToken";
import Chats from "./components/Chats";

const App: React.FunctionComponent = () => {
    const {token, setToken} = useToken();
    if (!token) {
        return <Login setToken={setToken}/>
    }
    return (
        <Router>
            <Header/>
            <main>
                <div className="container-fluid">
                    <Routes>
                        <Route path='/' element={<Overview/>}/>
                        <Route path='/predictions' element={<Predictions/>}/>
                        <Route path='/chats' element={<Chats/>}/>
                        <Route path='/analytics' element={<Analytics/>}/>
                    </Routes>
                </div>
            </main>
        </Router>
    );
}

export default App;
