import AxiosService from "./AxiosService";
import Chat from "../types/Chat";

const findAll = (page: number, types: Array<string>) => {
    const request = {
        "page": page,
        "types": types
    }
    return AxiosService.post<Chat>("/chats", request)
};

const ChatService = {
    findAll
};

export default ChatService;