export const HeaderData = [
    {
        title: 'Обзор',
        path: '/',
    },
    {
        title: 'Предсказания',
        path: '/predictions',
    },
    {
        title: 'Чаты',
        path: '/chats',
    },
    {
        title: 'Аналитика',
        path: '/analytics',
    },
]