import React, {Component} from 'react';
import AnalyticsService from "../services/AnalyticsService";
import UsageStats from "../types/UsageStats";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconName} from "@fortawesome/fontawesome-common-types";

type OverviewState = {
    stats: UsageStats
}

interface OverviewProps {
}

export class Overview extends Component<OverviewProps, OverviewState> {

    state = {
        stats: {
            countAll: 0n,
            countAllToday: 0n,
            countUniqueChats: 0n,
            countUniqueChatsToday: 0n,
            latestPredictions: []
        } as UsageStats
    }

    constructor(props: OverviewProps) {
        super(props);
        this.fetchStats = this.fetchStats.bind(this)
    }

    fetchStats() {
        AnalyticsService.getStats().then((response: any) => {
            this.setState({
                stats: response.data
            })
        })
    }

    componentDidMount() {
        this.fetchStats()
        setInterval(this.fetchStats, 3000)
    }

    render() {
        const stats = this.state.stats
        return (
            <>
                <div className="row">
                    {this.primaryMagicWandCard("Сегодня", stats.countAllToday.toString())}
                    {this.primaryMagicWandCard("Всего", stats.countAll.toString())}
                    {this.commentInfoCard("Сегодня", stats.countUniqueChatsToday.toString())}
                    {this.commentInfoCard("Всего", stats.countUniqueChats.toString())}
                </div>

                <br/>
                <h2>Последние предсказания</h2>
                <br/>

                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col" style={{width: '7%'}}>Дата</th>
                        <th scope="col" style={{width: '15%'}}>Чат</th>
                        <th scope="col" style={{width: '15%'}}>Юзер</th>
                        <th scope="col" style={{width: '70%'}}>Предсказание</th>
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">
                    {stats.latestPredictions.map((item, index) => {
                        return (
                            <tr key={item.id.toString()}>
                                <td>{item.date}</td>
                                <td>{item.chat}</td>
                                <td>{item.member}</td>
                                <td>{item.text}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </>
        )
    }

    commentInfoCard(header: string, value: string) {
        return this.card(header, value, "info", "comment")
    }

    primaryMagicWandCard(header: string, value: string) {
        return this.card(header, value, "primary", "magic-wand-sparkles")
    }

    card(header: string, value: string, type: string, iconName: IconName) {
        return (
            <div className="col-xl-3 col-md-6 mb-4">
                <div className={'card border-left-' + type + ' shadow h-100 py-2'}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    {header}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">{value}</div>
                            </div>
                            <div className="col-auto">
                                <FontAwesomeIcon icon={iconName} className="fa-2x text-gray-300"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
