import React, {BaseSyntheticEvent, useState} from "react";
import AuthService from "../services/AuthService";
import AuthResponse from "../types/AuthResponse";

interface LoginProps {
    setToken: (token: string) => void;
}

const Login: React.FunctionComponent<LoginProps> = (props) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null)

    const handleSubmit = (e: BaseSyntheticEvent) => {
        e.preventDefault()
        AuthService.auth(username, password).then(r => {
            const response = r.data as AuthResponse
            props.setToken(response.token)
        }).catch(e => {
            if (e.response.status === 404) {
                setError("Логин/пароль не найден(ы)")
            } else {
                setError(e.response.message)
            }
        })
    }

    return (
        <>
            <main className="form-signin">
                <form onSubmit={handleSubmit}>
                    <img className="mb-4" src="/images/fortuneteller-logo.jpeg"/>
                    <h1 className="h3 mb-3 fw-normal">Пожалуйста, представься</h1>

                    <div className="form-floating">
                        <input type="text"
                               className="form-control"
                               placeholder="Username"
                               required={true}
                               onChange={e => setUsername(e.target.value)}
                        />
                        <label htmlFor="username">Имя</label>
                    </div>
                    <div className="form-floating">
                        <input type="password"
                               className="form-control"
                               placeholder="Password"
                               required={true}
                               onChange={e => setPassword(e.target.value)}
                        />
                        <label htmlFor="password">Пароль</label>
                    </div>
                    {error == null ? <div/> : <div className="alert alert-danger" role="alert">
                        {error}
                    </div>}
                    <button className="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
                </form>
                <br/><br/>
            </main>
        </>
    )
}

export default Login;