import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import '@fortawesome/fontawesome-svg-core/styles.css'

// not sure why this hack is needed, but FA does not work without it
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faComment, faWandMagicSparkles} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faComment, faWandMagicSparkles)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
