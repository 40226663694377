import {encode} from "base64-arraybuffer";
import PredictionMedia from "../../types/PredictionMedia";
import React from "react";

export const mediaInput = (onChange: (media: PredictionMedia) => void) => {
    return <>
        <input
            id="prediction-file-input"
            type="file"
            accept=".jpeg,.png"
            className="form-control"
            onChange={(e) => {
                if ((e.target as HTMLInputElement)?.files?.length) {
                    const media = (e.target as HTMLInputElement).files![0]
                    const failedValidation = media.type !== 'image/jpeg' && media.type !== 'image/png'
                    if (failedValidation) {
                        alert("Unknown media type: " + media.type + "; Skipping everything")
                    } else {
                        media.arrayBuffer().then(bytes => {
                            const predictionMedia = {
                                data: encode(bytes),
                                type: media.type,
                            } as PredictionMedia
                            onChange(predictionMedia)
                        })
                    }
                }
            }}
        />
    </>
}